<template>
	<v-container id="pending-loans" fluid tag="section">
		<loader :active="loaderActive" />
		<v-row
			><v-col cols="12" sm="6" lg="6">
				<base-material-stats-card
					color="success"
					icon="mdi-chart-donut"
					title="Total Pending"
					:value="
						responsePayload.total ? responsePayload.total.toString() : '0'
					"
				/>
			</v-col>
			<v-col cols="12" sm="6" lg="6">
				<base-material-stats-card
					color="primary"
					icon="mdi-chart-donut"
					title="Total Amount"
					:value="
						responsePayload.totalAmt ? 'K' + responsePayload.totalAmt : '0.00'
					"
				/>
			</v-col>
			<v-col cols="12" md="12">
				<v-row rows="12" justify="end">
					<div class="my-2">
						<template>
							<!-- <v-dialog v-model="dialog" max-width="500px">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										elevation="9"
										color="primary"
										x-large
										v-bind="attrs"
										v-on="on"
									>
										<v-icon left>mdi-plus-circle</v-icon>
										Issue Loan
									</v-btn>
								</template>
								<v-card>
									<v-toolbar dark color="primary">
										<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
										<v-spacer></v-spacer>
										<v-toolbar-items>
											<v-btn icon dark @click="dialog = false">
												<v-icon>mdi-close</v-icon>
											</v-btn>
										</v-toolbar-items>
									</v-toolbar> 
									<v-card-text>
										<v-container>
											<v-row>
												<v-col cols="12" sm="6" md="4">
													<v-text-field
														v-model="editedItem.lname"
														label="Customer"
													></v-text-field>
												</v-col>
												<v-col cols="12" sm="6" md="4">
													<v-text-field
														v-model="editedItem.fname"
														label="Tenure"
													></v-text-field>
												</v-col>
												<v-col cols="12" sm="6" md="4">
													<v-text-field
														v-model="editedItem.phone"
														label="Amount(ZMW)"
													></v-text-field>
												</v-col>
											</v-row>
										</v-container>
									</v-card-text>

									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn color="primary" text @click="close"> Cancel </v-btn>
										<v-btn color="primary" text @click="save"> Save </v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog> -->
							<v-dialog v-model="dialogMarkAsProcessed" max-width="500px">
								<v-card>
									<v-card-title class="text-h5"
										>Please confirm overriding the system to manually disburse
										K{{ editedItem.amount }} to
										{{
											editedItem.customer.firstName +
												" " +
												editedItem.customer.lastName
										}}
										?</v-card-title
									>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn
											color="primary"
											text
											@click="closeDialogMarkAsProcessed"
											>Cancel</v-btn
										>
										<v-btn color="primary" text @click="markAsProcessedConfirm"
											>OK</v-btn
										>
										<v-spacer></v-spacer>
									</v-card-actions>
								</v-card>
							</v-dialog>
						</template>
					</div>
				</v-row>
			</v-col>

			<v-col cols="12" md="12">
				<base-material-card color="primary" class="px-5 py-3">
					<template v-slot:heading>
						<div class="display-2 font-weight-dark lightgray--text">
							Pending Requests
							<button class="float-right">Download</button>
							<v-spacer />
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Search"
								single-line
								hide-details
								color="lightgray"
							/>
						</div>
					</template>
					<v-card-text>
						<v-data-table
							:headers="headers"
							:items="formatedItems"
							:search="search"
							:single-expand="singleExpand"
							:expanded.sync="expanded"
							item-key="id"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							class="elevation-1"
							@click:row="(item, slot) => slot.expand(!slot.isExpanded)"
						>
							<template v-slot:item.amount="{ item }">
								<div :class="'font-weight-medium'">K{{ item.amount }}</div>
							</template>
							<template v-slot:[`item.actions`]="{ item }">
								<v-icon @click="markAsProcessed(item)">
									mdi-check-outline
								</v-icon>
							</template>
							<template v-slot:no-data>
								<v-btn color="primary" @click="initialize"> Reset </v-btn>
							</template>
						</v-data-table>
					</v-card-text>
				</base-material-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
	import store from "../../../../store";
	import loaderMixin from "../../../../mixins/loader";
	import { DateUtil } from "../../../../utils/dateUtil";
	export default {
		components: {
			Loader: () => import("../../../../components/Loader"),
		},
		mixins: [loaderMixin],
		data: () => ({
			search: "",
			expanded: [],
			sortBy: "id",
			sortDesc: true,
			singleExpand: false,
			responsePayload: {},
			dialogMarkAsProcessed: false,
			headers: [
				{
					sortable: true,
					text: "Date",
					value: "createdAt",
				},
				{
					sortable: true,
					text: "Last Name",
					value: "customer.lastName",
				},
				{
					sortable: true,
					text: "First Name",
					value: "customer.firstName",
				},
				{
					sortable: false,
					text: "Type",
					value: "transactionType.name",
				},
				{
					sortable: true,
					text: "Maturity Date",
					value: "maturityDate",
				},
				{
					sortable: true,
					text: "Amount(ZMW)",
					value: "amount",
					class: "font-weight-black",
				},
				{ text: "Override", value: "actions", sortable: false, align: "right" },
			],
			items: [],
			editedIndex: -1,
			editedItem: {
				amount: "",
				amountPlusInterest: "",
				chargeAmount: "",
				chargePercentage: "",
				createdAt: "",
				customer: {},
				customerAccount: "",
				disburedAmount: "",
				externalTransactionID: "",
				id: null,
				interestPercentage: "",
				isWithdrawn: false,
				maturityDate: "",
				status: {},
				tenure: "",
				transactionType: {},
				updatedAt: "",
				updatedBy: "",
			},
			defaultItem: {
				amount: "",
				amountPlusInterest: "",
				chargeAmount: "",
				chargePercentage: "",
				createdAt: "",
				customer: {},
				customerAccount: "",
				disburedAmount: "",
				externalTransactionID: "",
				id: null,
				interestPercentage: "",
				isWithdrawn: false,
				maturityDate: "",
				status: {},
				tenure: "",
				transactionType: {},
				updatedAt: "",
				updatedBy: "",
			},
		}),
		created() {
			this.initialize();
		},
		computed: {
			formatedItems() {
				return this.items.map((item) => ({
					...item,
					createdAt: DateUtil.DateAndTime(item.createdAt),
					aging: DateUtil.LoanAge(item.maturityDate),
					maturityDate: DateUtil.DateAndTime(item.maturityDate),
				}));
			},
		},
		methods: {
			async initialize() {
				this.showLoader();
				await this.$store.dispatch("transaction/fetchPendingLoans"),
					(this.items = store.state.transaction.pendingLoans.data);
				this.responsePayload = store.state.transaction.pendingLoans;
				this.hideLoader();
			},
			markAsProcessed(item) {
				this.editedIndex = this.items.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.dialogMarkAsProcessed = true;
			},
			closeDialogMarkAsProcessed() {
				this.dialogMarkAsProcessed = false;
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			},
			async markAsProcessedConfirm() {
				this.showLoader();
				await this.$store.dispatch("transaction/updateTransactionAsDisbursed", {
					id: this.editedItem.id,
					statusID: 1,
				});
				this.closeDialogMarkAsProcessed();
				this.hideLoader();
			},
		},
		mounted() {
			document.title = "Pending Loans | Kredwise";
		},
	};
</script>

<style lang="scss" scoped></style>
